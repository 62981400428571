<template>
  <v-combobox
    v-bind="$attrs"
    :append-inner-icon="dropdownIcon"
    bg-color="white"
    clear-icon="mdi-close"
    color="interactive"
    item-color="interactive"
    :item-title="itemTitle"
    :menu-props="menuProps"
    single-line
    variant="outlined"
  >
    <template v-for="(_, name) in $slots" v-slot:[name]="slotData" :key="name">
      <slot v-bind="slotData" :key="name" :name="name" />
    </template>
  </v-combobox>
</template>

<script>
export default {
  name: 'qtm-combobox',
  props: {
    itemTitle: {
      type: [Function, String],
      default: 'text'
    }
  },
  computed: {
    dropdownIcon() {
      if (this.$attrs.items.length) {
        return 'mdi-chevron-down'
      }
      return ''
    },
    menuProps() {
      return { contentClass: 'qtm-border', maxHeight: 300, ...this.$attrs['menu-props'] }
    }
  }
}
</script>
